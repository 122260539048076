import styled from 'styled-components/macro';
import { breakpoints } from '@styles/vars'
import theme from 'styled-theming';

const bg = theme('theme', {
    light: '#fff',
    dark: '#2a2f32'
});

export const Container = styled.div`
  position: absolute;
  width: 300px;  
  background: ${bg};
  box-shadow: 0 1px 16px rgba(20, 46, 110, 0.4);
  border-radius: 8px;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity var(--transition);
  opacity: 0;
  visibility: hidden;

  ${breakpoints.mobileS} {
    width: 300px;
  }

  ${breakpoints.mobileM} {
    width: 350px;
  }

  ${breakpoints.mobileL} {
    width: 395px;
  }
  
  ${breakpoints.tablet} {
    width: 540px;
  }



  &.visible {
    opacity: 1;
    visibility: visible;
  }

  .infos {
    display: flex;

    flex-direction: column;
    align-items: center;
    gap: 20px;

    .search {
      width: 100%;
    }

    .accountContainer {
      display: flex;
      flex-direction: column;
      height: 300px;
      overflow-y: scroll;
      width: 100%;
      gap: 8px;

      .account {
        position: relative;
        width: 100%;

        cursor: pointer;

        min-height: 80px;
        height: 80px;
        background-color: rgb(250, 251, 253);

        display: flex;
        align-items: center;
        gap: 12px;

        padding: 8px;

        border-radius: 4px;

        .avatar {
          width: 30px;
          heihgt: 30px;
        }

        .address {
          font-size: 14px;
        }

        .notifications {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;

          content: " ";
          width: 7px;
          height: 7px;
          background-color: rgb(253, 202, 64);
          border-radius: 7px;
        }

        .hidden {
          display: none;
        }
      }

      .selected {
        background-color: rgba(52, 209, 191, .2);
      }
    }
  }

  button {
    margin-top: 15px;
  }

`;
