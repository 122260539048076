import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: rgb(241, 245, 248);

  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);

    width: 300px;
    height: 8px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;

    .loader-bar {
      height: 100%;
      background-color: rgb(35, 134, 130);
      transition: width 0.2s ease-out;
    }

    .loader-text {
      font-size: 12px;
      font-weight: bold;
      color: #fff;
    }
  }
`;
