import { api } from "./api";

export const login = async (body) =>
  await api.post("/account/patient/log-in", body);

export const getProfileInfo = async (profile_id) =>{
  return await api.get(`/ordination/profile?profile_id=${profile_id}`);
}
export const updateUser = async (body) =>
  await api.put("/account/ordination", body);

export const refresh_cookies = async (body) =>
  await api.post("/account/patient/token", body);


export const getAccountInfo = async () =>{
  return await api.get("/account/ordination");
}

export const getMyOrdinations = async () => {
  return await api.get('/ordination/accounts')
}
