import { parseToUrlParams } from "../utils";
import { api } from "./api";

export const getAllCategories = async (profile_id) =>
  await api.get(`/ordination/exam/category?profile_id=${profile_id}`);

export const getAllExamTypes = async () =>
  await api.get("/ordination/exam/type");

export const getAllExamSpecies = async (profile_id) =>
  await api.get(`/ordination/exam/specie?profile_id=${profile_id}`);

export const getAllExams = async (profile_id) =>
  await api.get(`/ordination/exam?profile_id=${profile_id}`)


export const getExamById = async (id, profile_id) =>
  await api.get(`/ordination/exam/?id=${id}&profile_id=${profile_id}`)

export const createExam = async (body, profile_id) =>
  await api.post(`/exam?profile_id=${profile_id}`, body);


export const updateExam = async (body) =>
await api.put("/exam", body);
