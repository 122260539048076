// styled components
import {Menu, UserWrapper} from '../style';
import { IMAGE_ROUTE_URL } from 'services/api';

// components
import Avatar from '@ui/Avatar';
import { useUserContext } from '@contexts/userContext';

// utils
import {ClickAwayListener} from '@mui/base/ClickAwayListener';
import {useState} from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';

// assets
import doc1jpg from '@assets/avatars/doc1.jpg';
import doc1webp from '@assets/avatars/doc1.jpg?as=webp';
import placeholderSvgClinic from '@assets/avatars/klinika_placeholder.svg';



const CurrentUser = () => {
    const [open, setOpen] = useState(false);
    const handleClickAway = () => setOpen(false);
    const handleClick = () => setOpen(!open);
    const { user, setChangeAccountPopupOpen } = useUserContext();

  const { signOut } = useAuthenticator()

    let imageurl = user?.image  ?`${IMAGE_ROUTE_URL}${user["image"]}` : null
    const src = {
        jpg: imageurl ? imageurl : null,
    }

  const openAccountModal = () => {
    setChangeAccountPopupOpen(true)
  }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <UserWrapper>
                <Avatar type='clinic' avatar={src.jpg ? src : null} alt="avatar"/>
                <div className="info">
                    <span className="h3">{user?.name}</span>
                    <span className="position">klinika</span>
                    <Menu className={open ? 'visible' : ''}>
                        <button onClick={openAccountModal}>
                            <i className="icon icon-circle-user" /> Promjeni profil
                        </button>
                        <button onClick={signOut}>
                            <i className="icon icon-logout" /> Odjavi se
                        </button>
                    </Menu>
                </div>
                <button className="trigger" onClick={handleClick} aria-label="Show menu">
                    <i className="icon icon-chevron-down" />
                </button>
            </UserWrapper>
        </ClickAwayListener>
    )
}

export default CurrentUser;
