import React, { useState, useEffect } from "react";
import { Container } from "./style"

const Loader = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev < 96 ? prev + 1 : 96));
    }, 30); // Increase progress by 1% every 50ms

    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <div className="loader-container">
        <div className="loader-bar" style={{ width: `${progress}%` }}></div>
        <span className="loader-text">{progress}%</span>
      </div>
    </Container>
  );
};

export default Loader;
