import React, { useState, useEffect } from 'react';

import { Container } from './style';

import { useUserContext } from '@contexts/userContext';

import Avatar from '@ui/Avatar';

import Btn from '@ui/Btn';
import SearchBar from '@ui/SearchBar';

import ModalWindow from '@components/ModalWindow';

import { IMAGE_ROUTE_URL } from 'services/api';

const ChangeAccountPopup = () => {
  const [filteredOrdinations, setFilteredOrdinations] = useState([])
  const [filterOrdinations, setFilterOrdinations] = useState("")

  const { ordinations, changeAccountPopupOpen: open, setChangeAccountPopupOpen: setOpen, selectedOrdination, setSelectedOrdination } = useUserContext();

  useEffect(() => {
    if(ordinations) {
      const filtered = ordinations.filter(ordination => ordination.name?.toLowerCase().includes(filterOrdinations.toLowerCase()) || ordination.city?.toLowerCase().includes(filterOrdinations.toLowerCase()) || ordination.address?.toLowerCase().includes(filterOrdinations.toLowerCase()))

      setFilteredOrdinations(filtered)
    }
  }, [ordinations, filterOrdinations])

  const selectHandler = () => {
    setOpen(false)
  }

  const changeAccount = id => {
    setSelectedOrdination(id)
  }

  return (
    <ModalWindow isVisible={open} visibilityHandler={() => setOpen(false)}>
      <Container className={open ? 'visible' : ''}>
        <div className="infos">
          <h1>Izaberite ordinaciju</h1>

          <div className="search">
            <SearchBar placeholder="Pretražite klinike" handler={setFilterOrdinations} value={filterOrdinations}/>
          </div>

          <div className="accountContainer">
            {filteredOrdinations.map((item, idx) => {
              const imageurl = item?.image  ?`${IMAGE_ROUTE_URL}${item["image"]}` : null

              const src = {
                jpg: imageurl || null
              }

              return (
                <div className={`account ${selectedOrdination === item.id ? "selected" : ""}`} key={idx} onClick={() => changeAccount(item.id)}>
                  <div className="avatar">
                    <Avatar type='clinic' avatar={imageurl ? src : null} alt="avatar" />
                  </div>

                  <div>
                    <h2>{item?.name}</h2>
                    <p className="address">{item?.city} - {item?.address}</p>
                  </div>

                  <div className={`notifications ${false ? "hidden" : ""}`} />
                </div>
              )
            })}
          </div>

        </div>

        <Btn text="Vrati se nazad" handler={selectHandler} />
      </Container>
    </ModalWindow>
  );
}

export default ChangeAccountPopup;
