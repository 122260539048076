import { createContext, useContext, useEffect, useState } from 'react';
import { getProfileInfo } from 'services/auth';

export const userContext = createContext(undefined);


export const UserContextApi = ({ children, ordinations, setSelectedOrdination, selectedOrdination }) => {
  const [user, setUser] = useState(null);
  const [changeAccountPopupOpen, setChangeAccountPopupOpen] = useState(false)

  const refreshUser = () => {
    // Ovdje možete ponovno pozvati getProfileInfo kako biste ažurirali korisničke podatke
    getProfileInfo(selectedOrdination).then((userData) => {
        setUser(userData?.data?.results ? userData?.data?.results[0] : {});
    });
  };

  useEffect(() => {
    // Ovdje možete asinkrono dohvatiti korisničke podatke
    if(selectedOrdination) {
      getProfileInfo(selectedOrdination).then((userData) => {
          setUser(userData?.data?.results ? userData?.data?.results[0] : {});
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <userContext.Provider
      value={{
        user,
        refreshUser, // Dodali smo funkciju za osvježavanje korisnika
        setChangeAccountPopupOpen,
        changeAccountPopupOpen,
        selectedOrdination,
        setSelectedOrdination,
        ordinations,
      }}
      key={selectedOrdination}
    >
      {children}
    </userContext.Provider>
  );
};

export const useUserContext = () => useContext(userContext);
